@tailwind base;
@tailwind components;
@tailwind utilities;

/*
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

.sectionHeight {
  min-height: calc(100vh - 64px);
}

.error {
  padding: 16px;
  border-radius: 6px;
  background: rgb(238, 133, 133);
  color: rgb(87, 0, 0);
  font-style: italic;
  text-align: center;
  border: 1px solid rgb(87, 0, 0);
}

.success {
  padding: 16px;
  border-radius: 6px;
  background: rgb(164, 255, 187);
  color: rgb(0, 87, 19);
  font-style: italic;
  text-align: center;
  border: 1px solid rgb(0, 87, 19);
}
*/

/*
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(250 204 21) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
*/

/* STYLEGUIDE */
@font-face {
  font-family: "Vampiro One";
  src: url("../src/assets/fonts/VampiroOne-Balkan.ttf");
}

:root {
  --font-h1-primetitle-font-family: "Vampiro One", Helvetica, sans-serif;
  --font-h1-primetitle-font-size: 16px;
  --font-h1-primetitle-font-style: normal;
  --font-h1-primetitle-font-weight: 400;
  --font-h1-primetitle-letter-spacing: 0px;
  --font-h1-primetitle-line-height: normal;
  --font-h2-title-font-family: "Chakra Petch", Helvetica, sans-serif;
  --font-h2-title-font-size: 16px;
  --font-h2-title-font-style: normal;
  --font-h2-title-font-weight: 700;
  --font-h2-title-letter-spacing: 0px;
  --font-h2-title-line-height: normal;
  --font-h3-subtitle-font-family: "Space Mono", Helvetica, sans-serif;
  --font-h3-subtitle-font-size: 16px;
  --font-h3-subtitle-font-style: normal;
  --font-h3-subtitle-font-weight: 400;
  --font-h3-subtitle-letter-spacing: 0px;
  --font-h3-subtitle-line-height: normal;
  --font-h4-lead-font-family: "Source Code Pro", Helvetica, sans-serif;
  --font-h4-lead-font-size: 16px;
  --font-h4-lead-font-style: normal;
  --font-h4-lead-font-weight: 500;
  --font-h4-lead-letter-spacing: 0px;
  --font-h4-lead-line-height: normal;
  --font-p-paragraph-font-family: "Libre Franklin", Helvetica, sans-serif;
  --font-p-paragraph-font-size: 16px;
  --font-p-paragraph-font-style: normal;
  --font-p-paragraph-font-weight: 400;
  --font-p-paragraph-letter-spacing: 0px;
  --font-p-paragraph-line-height: normal;
  --font-a-anchor-font-family: "Chakra Petch", Helvetica, sans-serif;
  --font-a-anchor-font-size: 16px;
  --font-a-anchor-font-style: normal;
  --font-a-anchor-font-weight: 700;
  --font-a-anchor-letter-spacing: 0px;
  --font-a-anchor-line-height: normal;
  --font-button-font-family: "Chakra Petch", Helvetica, sans-serif;
  --font-button-font-size: 16px;
  --font-button-font-style: normal;
  --font-button-font-weight: 700;
  --font-button-letter-spacing: 0px;
  --font-button-line-height: normal;
  --nbwhite: rgba(255, 255, 255, 1);
  --nbblack: rgba(0, 0, 0, 1);
  --nbgreydark: rgba(43, 83, 93, 1);
  --nbgreymiddark: rgba(81, 122, 134, 1);
  --nbgreymain: rgba(144, 176, 185, 1);
  --nbgreylight: rgba(233, 239, 240, 1);
  --nbgreendark: rgba(24, 106, 92, 1);
  --nbgreenmain: rgba(70, 190, 164, 1);
  --nbgreenlight: rgba(158, 216, 210, 1);
  --nbbluedark: rgba(17, 112, 148, 1);
  --nbbluemain: rgba(49, 175, 246, 1);
  --nbbluelight: rgba(152, 221, 246, 1);
  --nbpurpledark: rgba(36, 26, 154, 1);
  --nbpurplemain: rgba(95, 82, 241, 1);
  --nbpurplelight: rgba(201, 185, 248, 1);
  --nbpinkdark: rgba(164, 125, 125, 1);
  --nbpinkmain: rgba(253, 176, 159, 1);
  --nbpinklight: rgba(255, 215, 215, 1);
  --nbreddark: rgba(160, 37, 10, 1);
  --nbredmain: rgba(244, 69, 30, 1);
  --nbredlight: rgba(251, 208, 184, 1);
  --nbyellowdark: rgba(115, 99, 9, 1);
  --nbyellowmain: rgba(235, 201, 13, 1);
  --nbyellowlight: rgba(248, 235, 163, 1);
  --nborangedark: rgba(184, 113, 8, 1);
  --nborangemain: rgba(233, 167, 67, 1);
  --nborangelight: rgba(255, 225, 181, 1);
}

/* GENERAL ELEMENTS */
/* *::-webkit-scrollbar {
  display: none;
} */

html {
  overflow: -moz-scrollbars-none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  caret-color: var(--nbgreenmain);
  caret-shape: block;
  /*-ms-overflow-style: none;   IE and Edge */
  /*scrollbar-width: none;   Firefox */
}

body {
  /*scrollbar-width: none;
  scrollbar-height: none;*/
  overflow-x: hidden;
  touch-action: pan-y;
  text-align: left;
  word-break: break-word;
  text-wrap: balance;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-h3-subtitle-font-family);
  background-color: var(--nbgreymiddark);
  color: var(--nbgreenmain);
}

::selection {
  color: var(--nbgreylight);
  background-color: var(--nbgreenmain);
}

#root {
  background-color: var(--nbgreydark);
  color: var(--nbgreenmain);
}

#root:has(.App > .justify-center) {
  background-color: var(--nbgreylight);
  color: var(--nbgreenmain);
}

/* navbar */
header {
  width: 100%;
  background-color: var(--nbgreylight);
}

/* footer */
footer {
  width: 100%;
  min-height: 10rem;
  background-color: var(--nbgreymiddark);
  color: var(--nbgreenmain);
}

/* tailwind flexgrid assistance */
#innerGrid {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-content: center;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#gridRow {
  gap: 1rem;
}

#footerGrid {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
}

/* INNERGRID COMPONENTS */

.line-pattern {
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

/* GraphFieldRace width */

.nb-w-1 {
  width: 1%;
}
.nb-w-2 {
  width: 2%;
}
.nb-w-3 {
  width: 3%;
}
.nb-w-4 {
  width: 4%;
}
.nb-w-5 {
  width: 5%;
}
.nb-w-6 {
  width: 6%;
}
.nb-w-7 {
  width: 7%;
}
.nb-w-8 {
  width: 8%;
}
.nb-w-9 {
  width: 9%;
}
.nb-w-10 {
  width: 10%;
}
.nb-w-11 {
  width: 11%;
}
.nb-w-12 {
  width: 12%;
}
.nb-w-13 {
  width: 13%;
}
.nb-w-14 {
  width: 14%;
}
.nb-w-15 {
  width: 15%;
}
.nb-w-16 {
  width: 16%;
}
.nb-w-17 {
  width: 17%;
}
.nb-w-18 {
  width: 18%;
}
.nb-w-19 {
  width: 19%;
}
.nb-w-20 {
  width: 20%;
}
.nb-w-21 {
  width: 21%;
}
.nb-w-22 {
  width: 22%;
}
.nb-w-23 {
  width: 23%;
}
.nb-w-24 {
  width: 24%;
}
.nb-w-25 {
  width: 25%;
}
.nb-w-26 {
  width: 26%;
}
.nb-w-27 {
  width: 27%;
}
.nb-w-28 {
  width: 28%;
}
.nb-w-29 {
  width: 29%;
}
.nb-w-30 {
  width: 30%;
}
.nb-w-31 {
  width: 31%;
}
.nb-w-32 {
  width: 32%;
}
.nb-w-33 {
  width: 33%;
}
.nb-w-34 {
  width: 34%;
}
.nb-w-35 {
  width: 35%;
}
.nb-w-36 {
  width: 36%;
}
.nb-w-37 {
  width: 37%;
}
.nb-w-38 {
  width: 38%;
}
.nb-w-39 {
  width: 39%;
}
.nb-w-40 {
  width: 40%;
}
.nb-w-41 {
  width: 41%;
}
.nb-w-42 {
  width: 42%;
}
.nb-w-43 {
  width: 43%;
}
.nb-w-44 {
  width: 44%;
}
.nb-w-45 {
  width: 45%;
}
.nb-w-46 {
  width: 46%;
}
.nb-w-47 {
  width: 47%;
}
.nb-w-48 {
  width: 48%;
}
.nb-w-49 {
  width: 49%;
}
.nb-w-50 {
  width: 50%;
}
.nb-w-51 {
  width: 51%;
}
.nb-w-52 {
  width: 52%;
}
.nb-w-53 {
  width: 53%;
}
.nb-w-54 {
  width: 54%;
}
.nb-w-55 {
  width: 55%;
}
.nb-w-56 {
  width: 56%;
}
.nb-w-57 {
  width: 57%;
}
.nb-w-58 {
  width: 58%;
}
.nb-w-59 {
  width: 59%;
}
.nb-w-60 {
  width: 60%;
}
.nb-w-61 {
  width: 61%;
}
.nb-w-62 {
  width: 62%;
}
.nb-w-63 {
  width: 63%;
}
.nb-w-64 {
  width: 64%;
}
.nb-w-65 {
  width: 65%;
}
.nb-w-66 {
  width: 66%;
}
.nb-w-67 {
  width: 67%;
}
.nb-w-68 {
  width: 68%;
}
.nb-w-69 {
  width: 69%;
}
.nb-w-70 {
  width: 70%;
}
.nb-w-71 {
  width: 71%;
}
.nb-w-72 {
  width: 72%;
}
.nb-w-73 {
  width: 73%;
}
.nb-w-74 {
  width: 74%;
}
.nb-w-75 {
  width: 75%;
}
.nb-w-76 {
  width: 76%;
}
.nb-w-77 {
  width: 77%;
}
.nb-w-78 {
  width: 78%;
}
.nb-w-79 {
  width: 79%;
}
.nb-w-80 {
  width: 80%;
}
.nb-w-81 {
  width: 81%;
}
.nb-w-82 {
  width: 82%;
}
.nb-w-83 {
  width: 83%;
}
.nb-w-84 {
  width: 84%;
}
.nb-w-85 {
  width: 85%;
}
.nb-w-86 {
  width: 86%;
}
.nb-w-87 {
  width: 87%;
}
.nb-w-88 {
  width: 88%;
}
.nb-w-89 {
  width: 89%;
}
.nb-w-90 {
  width: 90%;
}
.nb-w-91 {
  width: 91%;
}
.nb-w-92 {
  width: 92%;
}
.nb-w-93 {
  width: 93%;
}
.nb-w-94 {
  width: 94%;
}
.nb-w-95 {
  width: 95%;
}
.nb-w-96 {
  width: 96%;
}
.nb-w-97 {
  width: 97%;
}
.nb-w-98 {
  width: 98%;
}
.nb-w-99 {
  width: 99%;
}
.nb-w-100 {
  width: 100%;
}